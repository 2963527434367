/* eslint-disable */
import axios from 'axios'
import { CALENDAR_API_CONFIG } from '@/config/calendar.api.config'

export default class calendarService {
                 /**
                  * @param {[String]} org_key [organisation key]
                  * @returns {[Object]}
                  */
                 static getMeetings = async (org_key, start_date = null, end_date = null) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getMeetings(org_key, start_date, end_date))
                   //logic can be added here
                   return request
                 }
                 /**
                  * @param {[String]} org_key [organisation key]
                  * @returns {[Object]}
                  */
                 static getMeetingSingle = async (org_key, mtg_key) => {
                   const request = await axios.get(CALENDAR_API_CONFIG.getMeetingSingle(org_key, mtg_key))
                   //logic can be added here
                   return request
                 }

                 /**
                  * @param {[String]} org_key [organisation key]
                  * @returns {[Object]}
                  */
                 static getStateMeetings = async (org_key, start_date = null, end_date = null) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getStateMeetings(org_key, start_date, end_date))
                   //logic can be added here
                   return request
                 }

                 /**
                  * @param {[String]} org_key [organisation key]
                  * @returns {[Object]}
                  */
                 static getStateMeetings = async (org_key, start_date = null, end_date = null) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getStateMeetings(org_key, start_date, end_date))
                   //logic can be added here
                   return request
                 }

                 /**
                  * [getMeetingRecurrence]
                  * @param {[String]} mtr_key [meeting recurrence key]
                  * @returns {[Object]}
                  */
                 static getMeetingRecurrence = async mtr_key => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getMeetingRecurrence(mtr_key))
                   //logic can be added here
                   return request
                 }
                 /**
                  * [meetingSoftDelete]
                  * @param {[String]} mtg_key [meeting key]
                  * @param {[String]} mtg_user [logon username]
                  * @returns {[Object]}
                  */
                 static meetingSoftDelete = async (mtg_key, mtg_user) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.meetingSoftDelete(mtg_key, mtg_user))
                   //logic can be added here
                   return request
                 }
                 /**
                  * [getEventCalenderFormSettings]
                  * @param {[String]} org_key [organisation key]
                  * @returns {[Array]}
                  */
                 static getEventCalenderFormSettings = async (org_key, event_type) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getEventCalendarFormSettings(org_key, event_type))
                   //logic can be added here
                   return request
                 }
                 /**
                  * [addNewEvent]
                  *  @param {[Object]} event_obj [event post parameters]
                  *  @returns {[Object]}
                  */
                 static addNewEvent = async event_obj => {
                   let post = await axios.post(CALENDAR_API_CONFIG.AddNewEvent(), event_obj)
                   return post
                 }
                 /**
                  * [AddNewStateEvent]
                  *  @param {[Object]} event_obj [event post parameters]
                  *  @returns {[Object]}
                  */
                 static addNewStateEvent = async event_obj => {
                   let post = await axios.post(CALENDAR_API_CONFIG.AddNewStateEvent(), event_obj)
                   return post
                 }
                 /**
                  * @returns {[Array]} [Arrays of states]
                  *
                  */
                 static GetStates = async country_key => {
                   let request = await axios.get(CALENDAR_API_CONFIG.getStates(country_key))
                   return request
                 }
                 /**
                  *
                  * @param {[Object]} data [address object]
                  */
                 static UpsertAddress = async data => {
                   let post = await axios.post(CALENDAR_API_CONFIG.upsertAddress(), data)
                   return post
                 }
                 /**
                  *
                  * @param {[Object]} data [virtual location object]
                  */
                 static UpsertVirtualLocation = async data => {
                   let post = await axios.post(CALENDAR_API_CONFIG.upsertVirtualLocation(), data)
                   return post
                 }

                 static DeleteByRecurrence = async (mtr_key, mtg_user) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.meetingDeleteByRecurrence(mtr_key, mtg_user))
                   return request
                 }
                 /**
                  *
                  * @param {[String]} org_key [Organisation key]
                  */
                 static MeetingToBeSchedule = async org_key => {
                   let request = await axios.get(CALENDAR_API_CONFIG.meetingToBeSchedule(org_key))
                   return request
                 }
                 /**
                  * @param {[String]} t_parent_org_key [State key or IO Association Key]
                  */
                 static GetOrganisationHierarchy = async (t_parent_org_key, org_key, lng_key) => {
                   let request = await axios.get(
                     CALENDAR_API_CONFIG.GetOrganisationHierarchy(t_parent_org_key, org_key, lng_key)
                   )
                   return request
                 }

                 /**
                  *
                  * @param {[String]} mtr_key [Meeting key]
                  */
                 static DeleteStateMeeting = async (mtr_key, delete_all) => {
                   let request = await axios.get(CALENDAR_API_CONFIG.deleteStateMeeting(mtr_key, delete_all))
                   return request
                 }
               }
