<template>
  <div class="calendar-month template-1">
    <page-header class="mx-lg">
      <h1>{{ translations.tcUSAScripture }}</h1>
    </page-header>
    <!-- / Page Header -->
    <page-body class="mx-lg">
      <section class="section-1 bg-block">
        <div class="row g-buttons mb-4">
          <div class="monthgrid">
            <Gbutton class="grid btn-outline btn-w-sm ml-3" @click.native="handleSwitch" ref="monthswitch"
              :class="{ 'btn-primary': switchM == 'month' }">
              {{ translations.tcMonth }}
            </Gbutton>
            <Gbutton class="month btn-outline btn-w-sm" @click.native="handleSwitch"
              :class="{ 'btn-primary': switchM == 'grid' }">
              {{ translations.tcGrid }}
            </Gbutton>
          </div>
          <div>
            <b-button @click="handleManageBlitzes" v-if="user_accessible_manage" variant="primary" size="sm"
              class="btn btn-tertiary mr-1">
              {{ translations.tcManageBlitzes }}
            </b-button>
            <b-button @click="handleAddBlitz" v-if="user_accessible_add" variant="primary" size="sm"
              class="btn btn-tertiary ml-1">
              {{ translations.tcAddBlitz }}
            </b-button>
          </div>
        </div>
        <section :class="switchM == 'month' ? 'fullcalvis' : 'fullcalinv'">
          <FullCalendar ref="fullcalendar2" :options="calendarOptions"></FullCalendar>
        </section>
        <div class="month-view" v-if="switchM == 'grid'">
          <div class="day-view" v-for="(day, index) in daysTranslated" :key="index">
            <h3>{{ handleDateFormat(day.startDate) }} - {{ handleDateFormat(day.endDate) }}</h3>
            <CalendarWeek :obj="day" :key="index" />
          </div>
        </div>
      </section>
    </page-body>
  </div>
  <!-- / Page Body -->
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { Calendar } from 'vue-bootstrap4-calendar'
import CalendarKey from '@/components/calendar/calendar-key/CalendarKey.vue'
import CalendarToolbar from '@/components/calendar/calendar-toolbar/CalendarToolbar.vue'
import CalendarWeek from '@/components/calendar/calendar-week/CalendarWeek.vue'
import CampSelect from '@/components/CampSelect.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

import allLocales from '@fullcalendar/core/locales-all'

import Gbutton from '@/components/Gbutton.vue'
import iAdd from '@/assets/svgs/icon-add-button.vue'
import iClose from '@/assets/svgs/icon-close.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import { Loader } from '@/mixins/Loader'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { securityMixin } from '@/mixins/securityMixin'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'usa-scripture-blitzes',
  mixins: [Loader, securityMixin, translationMixin],
  data() {
    return {
      daysTranslated: [],
      translations: {},
      switchM: 'grid',
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin],
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: '',
        },
        initialView: 'dayGridMonth',
        handleWindowResize: true,
        eventDisplay: 'block',
        selectable: false,
        weekends: true,
        locales: allLocales,
        locale: 'en',
        eventClick: this.selectedEvent,
      },
      secured_controls: {
        usa_scripture_blitz_add_button: '89e747cb-e645-45e7-9e1d-2944d1b144c9',
        usa_scripture_blitz_edit_button: 'bacf86bc-1c84-4487-9c9b-b44d0c3390c5',
        usa_scripture_blitz_delete_button: 'df8ee57a-6cd4-4948-985a-09e6c6dcb75a',
        usa_scripture_blitz_manage_button: 'f9ecf1d0-631b-4a4d-b4af-9b40b3dda480',
        calendar_add_event_button: '20a78086-7644-4a38-9162-085f27489166'
      },
      user_accessible_add: false,
      user_accessible_manage: false,
    }
  },
  methods: {
    ...mapActions({
      getControlPermissionsFromList: 'user/getControlPermissionsFromList',
      loadBlitzes: 'usaScriptureBlitz/loadAllBlitzes',
      setLoadingStatus: 'menu/setLoadingStatus',
      setSelectedUSABlitzKey: 'user/setSelectedUSABlitzKey',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.checkPermissions(),
          this.getViewTranslations(),
          this.getComponentTranslations('calendar-key', 'calendar-toolbar', 'camp-select'),
          this.getComponentTranslations('common.calendar-event-type'),
        ]).then((results) => {
          this.calendarOptions.locale = this.userLogin.pref_culture
          this.stripReadableText(results[3])
          const componentTranslations = {
            ...results[2],
            common: { ...this.translations.common },
          }
          this.$set(this.translations, 'components', componentTranslations)
        })
        await this.loadBlitzes()
        if (!!this.$refs.fullcalendar2) {
          let calendar = this.$refs.fullcalendar2.getApi()
          if (calendar.getEventSources().length > 0) {
            calendar.getEventSources()[0].remove()
          }
          const calEvents = []
          this.days.forEach((blz) => {
            // we do this date addition to get the correct end date for the event -- it's exclusive so the last day is excluded
            let d = new Date(blz.endDate)
            d.setDate(d.getDate() + 1)
            calEvents.push({
              allDay: true,
              title: blz.title,
              start: blz.startDate,
              end: d,
              attachment: blz.attachment ? blz.attachment : null,
              allDay: true,
              backgroundColor: '#003946',
              textColor: '#ffffff',
            })
          })
          calendar.addEventSource(calEvents)
        }
        this.translateDaysData()
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async checkPermissions() {
      await this.getControlPermissionsFromList(Object.values(this.secured_controls)).then(() => {
        this.user_accessible_add = this.determineAccessibility(this.secured_controls.usa_scripture_blitz_add_button),
          this.user_accessible_add = !!this.user_accessible_add ? true : this.determineAccessibility(this.secured_controls.calendar_add_event_button),
          this.user_accessible_manage = this.determineAccessibility(this.secured_controls.usa_scripture_blitz_manage_button),
          this.user_accessible_manage = !!this.user_accessible_manage ? true : this.determineAccessibility(this.secured_controls.calendar_add_event_button)
      })
    },
    async handleManageBlitzes() {
      this.$router.push({ name: 'manage-blitzes' })
    },
    async handleAddBlitz() {
      this.$router.push({ name: 'add-blitz' })
    },
    handleDateFormat(date) {
      const options = { month: 'long', day: 'numeric', year: 'numeric' }
      const dateDate = new Date(date)
      const newFormattedDate = dateDate.toLocaleDateString(this.prefCulture, options)
      return newFormattedDate
    },
    handleSwitch() {
      this.switchM = this.switchM === 'grid' ? 'month' : 'grid'
    },
    async selectedEvent(evt) {
      if (evt.event.extendedProps.attachment) {
        window.open(evt.event.extendedProps.attachment)
      } else {
        this.$swal({
          icon: 'error',
          text: this.translations.tcNoFlyer,
          confirmButtonText: this.translations.tcOk,
        })
      }
    },
    translateDaysData() {
      if (!this.days || !this.translations) return false
      const tcIntlRep = (this.translations.tcIntlRep || 'Intl Rep.') + ': '
      const tcLocalContact = (this.translations.tcLocalContact || 'Local Contact') + ': '
      const tcPhone = (this.translations.tcPhone || 'Phone') + ': '
      const tcEmail = (this.translations.tcEmail || 'Email') + ': '

      return this.daysTranslated = this.days.map(day => {
        const splitLabel = day.label.split('|')
        const dayPhone = splitLabel[0].trim()
        const dayEmail = splitLabel[1].trim()
        const comma = dayPhone ? ',' : ''

        return {
          ...day,
          eventname: tcIntlRep + day.eventname,
          description: tcLocalContact + day.description,
          label: `${tcPhone} ${dayPhone}${comma} ${tcEmail} ${dayEmail}`
        }
      })
    },
  },
  computed: {
    ...mapGetters({
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      usa_blitzes: 'usaScriptureBlitz/usa_blitzes',
      userLogin: 'user/userLogin',
    }),
    days() {
      //  Used to display date on grid display
      const days = []
      let yesterday = new Date()
      yesterday.setDate(yesterday.getDate() - 1)
      this.usa_blitzes.sort((a, b) => {
        return new Date(a.blz_start_date) - new Date(b.blz_start_date)
      })
      this.usa_blitzes.forEach((blz) => {
        if (new Date(blz.blz_start_date) >= yesterday) {
          const phone = blz.blz_local_contact_phone ? blz.blz_local_contact_phone : ''
          const email = blz.blz_local_contact_email ? blz.blz_local_contact_email : ''
          days.push({
            blzKey: blz.blz_key,
            title: blz.blz_name,
            eventname: blz.blz_intl_rep,
            description: blz.blz_local_contact_name,
            label: `${phone} | ${email}`,
            attachment: blz.blz_attachment,
            startDate: blz.blz_start_date,
            endDate: blz.blz_end_date,
            vmrt_trn_txt: 'Once',
            color: 'card-Gideon',
            flyer: this.translations.tcFlyer,
          })
        }
      })
      return days
    },
  },
  async created() {
    await this.page_load()
  },
  components: {
    Calendar: Calendar,
    CalendarKey: CalendarKey,
    CalendarToolbar: CalendarToolbar,
    CalendarWeek: CalendarWeek,
    campSelector: CampSelect,
    FullCalendar,
    Gbutton: Gbutton,
    iAdd: iAdd,
    iClose: iClose,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style lang="scss">
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.fullcalvis {
  visibility: visible;
  height: auto;
}

.fullcalinv {
  visibility: hidden;
  height: 0px;
}

.fc-event {
  font-size: 0.85em !important;
  background-color: #3a87ad;
  border-color: #3a87ad;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  cursor: pointer;
}

.fc-event-time {
  min-width: fit-content;
}

.fc .fc-col-header-cell-cushion {
  color: #636363;
  text-decoration: none;
}

.fc-timegrid-event {
  font-size: 0.9em !important;
  min-height: 27px;
  line-height: 0.95em;
}

.fc-daygrid-day-number {
  display: flex;
  text-decoration: none !important;
  justify-content: center;
  align-items: center;
  float: right;
  width: 30px;
  height: 30px;
  font-size: 14px;
  color: #636363;
  font-family: $open-sans;

  @include breakpoint(sm) {
    margin: 0 auto;
    float: none;
  }
}
</style>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';

.to-be-scheduled {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #fbc301;

  header {
    display: flex;

    h3 {
      color: #000;
      font-family: 'DIN 1451 W01 Engschrift', sans-serif;
      font-size: 30px;
      letter-spacing: 1px;
      line-height: 44px;
      text-transform: uppercase;
      flex: 1 1 auto;
      display: flex;

      @include breakpoint(sm) {
        justify-content: center;
      }
    }

    .i-tooltip {
      position: relative;
      top: 0px;
      margin-left: 10px;
      line-height: 0;

      svg path {
        fill: #000;
      }
    }
  }

  .scheduled-buttons {
    .btn {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;

      @include breakpoint(sm) {
        width: 100%;
        text-align: left;

        span {
          flex: 1 1 auto;
        }
      }

      svg {
        margin-left: 7px;
      }
    }
  }
}

.calendar-month {
  margin-bottom: 80px;

  .section-1 {
    padding-top: 30px;

    @include breakpoint(sm) {
      margin-top: 50px;
    }
  }

  .cal-key-cont {
    display: flex;
    justify-content: flex-end;
    margin-top: 35px;
    margin-bottom: 30px;

    .calendar-key:last-of-type .event-key {
      margin-right: 0;
    }

    @include breakpoint(sm) {
      display: none;
    }
  }

  .month-view {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;

    @include breakpoint(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    h3 {
      color: #000;
      font-size: 42px;
      line-height: 44px;
      margin-bottom: 30px;
      text-transform: none;

      @include breakpoint(sm) {
        padding-left: 55px;
        padding-right: 55px;
      }
    }

    .day-view {
      padding-bottom: 85px;

      @include breakpoint(sm) {
        padding-bottom: 30px;
      }
    }
  }

  .card {
    padding: 0;
    box-shadow: none !important;

    .card-header {
      display: none;
    }

    .card-block {
      .row>.col-sm-12>.row {
        display: none;
      }

      .full-calendar-body {
        .week-row {
          border-color: #efefef;

          .day-cell {
            padding: 5px 5px;
            min-height: 150px;

            @include breakpoint(sm) {
              min-height: 100px;
              border: none;

              >.row>.col-sm-6 {
                &:first-of-type {
                  display: none;
                }

                &:last-of-type {
                  width: 100%;
                }
              }
            }
          }

          .event-box {
            .card-header {
              display: block !important;
              font-size: 14px;
              padding: 8px 15px;
              border-bottom: none;

              @include breakpoint(sm) {
                display: none;
              }
            }

            .card {
              box-shadow: none !important;

              @include breakpoint(sm) {
                width: 13px;
                height: 13px;
                border: none !important;
                border-radius: 50%;
                background-color: #636363 !important;
                margin: 0 auto;
              }

              &.card-Gideon {
                background-color: #f1f4f5;
                border-left: 5px solid #003946;

                .card-header {
                  color: #003946;
                }
              }

              &.card-Auxillary {
                background-color: #fef4f1;
                border-left: 5px solid #fb4401;

                .card-header {
                  color: #fb4401;
                }
              }

              &.card-Both {
                background-color: #f3f1f5;
                border-left: 5px solid #2a0049;

                .card-header {
                  color: #2a0049;
                }
              }
            }
          }
        }
      }
    }
  }
}

.g-buttons {
  display: flex;
  justify-content: space-around;
}

.grid,
.month {
  font-weight: normal;
  text-transform: none;

  @include breakpoint(sm) {
    width: 33.333%;
    margin-right: 0;
  }

  &.btn-primary {
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
  }
}
</style>
